<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        prepend-icon="mdi-calendar"
        :value="displayFormattedDate"
        v-on="on"
        clearable
        readonly
        @click:clear="clearField"
        @change="dateChanged"
      />
    </template>
    <v-date-picker
      @change="dateChanged"
      v-model="formattedDate"
      no-title
      @input="dateMenu = false"
      class="dashDatePicker"
    />
  </v-menu>
</template>

<script>

export default {
	/**
	 * Takes in date in milliseconds, formats for v-date-picker and returns milliseconds again.
	 * @property {Number} selectedDate date YYYY/MM/DD in milliseconds
	 */
  name: "DashDatePicker",
  props: {
    label: {
      type: String,
      default: ''
    },
    selectedDate: {
      type: Number,
			default: Date.now()
    }
  },
  data () {
		return {
			dateMenu: false,
			formattedDate: null
		}
	},
	beforeMount () {
    if(this.selectedDate) {
      this.formattedDate = (new Date(this.selectedDate)).toISOString().slice(0, 10)
    } else {
      this.formattedDate = new Date().toISOString().substring(0, 10)
    }
    this.dateChanged()
	},
  computed: {
    displayFormattedDate() {
      // We want to show the date as DD-MM-YYYY
      if(this.formattedDate) {
        return this.formattedDate.split('-').reverse().join('-')
      }
      return null
    },
  },
  methods: {
    cancel() {
      this.$emit('onCancel')
    },
    dateChanged() {
      this.$emit('dateChanged', Date.parse(this.formattedDate))
    },
    clearField() {
      this.formattedDate = null
      this.dateChanged()
    }
  }
}
</script>

<style scoped>
.dashDatePicker {
  height: 360px !important;
}
</style>
